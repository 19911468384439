<template>
  <div id="active-reports" class="active-reports" style="height: 100vh" v-if="gcsSignInSheetTemplate!=''">
    <!-- <div class="download-button-section">
      <button class="btn btn-primary downlad-report-button" @touchstart="downloadReport" @click="downloadReport">Download As PDF</button>
    </div> -->
    <GcArViewer
      theme="ActiveReports"
      ref="reportViewer"
      :availableExports="availableExports"
      :sidebarVisible="true"
    ></GcArViewer>
  </div>
</template>

<script>
import Vue from 'vue';
import '@grapecity/activereports';
import { Viewer as ReportViewer } from '@grapecity/activereports-vue';
import "@grapecity/activereports/styles/ar-js-ui.css";
import "@grapecity/activereports/styles/ar-js-viewer.css";
// import { saveAs } from 'file-saver';

import { Core } from "@grapecity/activereports"; 
Core.setLicenseKey("reports.callsteward.ca|reports.callsteward.com|reports.callsteward.net,516284612545651#B1mFfNFUerh6ca3UarAHWyczVNB5MwoWMadXRUZGepBFarxGStNkQxNFNmFEMvhEWCl4dJFHUNBlVJpVYIJ7YJZ6KFVTbstUbENFV5ADR8VlY8J4aIJDcqxmNLhUcmdkMUxUaBdnVq34MqF7dvITeZpGdqFnMMpXOrN5ZOdVMaZFa7ljSBJFcwoEZDtSQysST9gHVUJnSpFDMUxmeVlnMHl7RyQDOItkQ4kzZllUdnVnQ6U7SzhUdBBjNBZVRxd5S5JXQWpkNBFGMwdneOVlN9ZzT43UMRljcNJzLXJ5QjVVZrp6bXZ5bMVXNqhUUsRnVtFXZtp4RuZHWlZUWyokUVlGODR4ZylGSvlXZkNmI0IyUiwiI6QzQ9MjM4IjI0ICSiwSNycjN4ETO6kTM0IicfJye&Qf35VfiQlRWllI0IyQiwiI4YFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsISM4YTMzADI5ITNwMjMwIjI0ICdyNkIsICdl9mLkJXY7VGdzxGbhNmLzRncvBXZyxSbvNmLkJXY7VGdzxGbhNmLzRncvBXZyxSYj9CZyF6dlR7csxWYj9yc4J7bwVmciojIz5GRiwiIkJXY7VGdTBCbsF6QiojIh94QiwiIxUjN5QTNyEjN4gjM6ETNiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TU4ZVea3GZkFEdXVGcvRjaYJUZE9mb59UTRNlT6gVRsVjYpBzKzYEe0tkQyk4SSpkRjpENXRTSyVlS4gTW8NmcaZTTJR7L8l5vh");

export default {
    components: {
        GcArViewer: ReportViewer
    },

  data() {
    return {
      loadingReport: false,
      gcsSignInSheetTemplate: '',
      dataSource: '',
      reportDocument: null,
      checkDocumentInterval: null,
      availableExports: ['pdf','xlsx','html','tabular-data'],
      exportsSettings: {
        pdf: {
          title: "Employee Report",
          filename: "EmployeeReport.pdf",
          author: "Callsteward",
          subject: "Web Reporting",
          keywords: "reporting, sample",
          printing: false,
          copying: false,
          modifying: false,
          annotating: false,
          contentAccessibility: false,
          documentAssembly: false,
          pdfVersion: "1.3",
          autoPrint: false
        },
        xlsx: {
          title: "EmployeeReport",
          filename: "EmployeeReport.xlsx",
          author: "Callsteward",
          subject: "Web Reporting",
          keywords: "reporting, sample",
          printing: false,
          copying: false,
          modifying: false,
          annotating: false,
          contentAccessibility: false,
          documentAssembly: false,
          pdfVersion: "1.3",
          autoPrint: false
        },
        html: {
          title: "EmployeeReport",
          filename: "EmployeeReport.html",
          author: "Callsteward",
          subject: "Web Reporting",
          keywords: "reporting, sample",    
          printing: false,
          copying: false,
          modifying: false,
          annotating: false,
          contentAccessibility: false,
          documentAssembly: false,
          pdfVersion: "1.3",
          autoPrint: false
        }
      },
    };
  },

  computed: {
    reportZoom() {
      return window.innerWidth > 1367 ? 150 : 100;
    },
  },

  async mounted() {
    this.gcsSignInSheetTemplate = atob(this.$route.params.url);
    this.dataSource = this.$route.query.data_source && this.$route.query.data_source !== '' 
                      ? atob(this.$route.query.data_source) 
                      : '';
    await this.onLoadFromFile(this.gcsSignInSheetTemplate);
  },

  methods: {
    async onLoadFromFile(url) {
      try {
        this.loading = true;
        // console.log(this.loading);
        if (this.dataSource && this.dataSource !== '') {
          let data = await this.loadData();
          await this.getAlienAPI(url).then(res => {
            res.data.DataSources[0].ConnectionProperties.ConnectString = "jsondata=" + JSON.stringify(data);
            this.$refs.reportViewer.Viewer().open(res.data);
          });
        } else {
          await this.getAlienAPI(url).then(res => {
            this.$refs.reportViewer.Viewer().open(res.data);
          });
        }
        this.loading = false;
        // console.log(this.loading);
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    cancelReportDownload() {
      // console.log("Cancel Triggered");
      return false;
    },

    // downloadReportBlob(reportData) {
      // not in use for viewr.downlad() functionality in the report download
      // const blob = new Blob([reportData], { type: 'application/pdf' });
      // Trigger download
      // saveAs(blob, 'EmployeeReport.pdf'); 
      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(blob);
      // link.download = 'EmployeeReport.pdf'; // Specify filename
      // link.click(); 

      // Cleanup
      // URL.revokeObjectURL(link.href);  // Release the object URL
      // link.remove();  // Remove the link element from the DOM
    // },

    // async downloadReport() {
      // console.log("Download Button Triggered");
      // const viewer = this.$refs.reportViewer.Viewer();
      // viewer.export('pdf', this.exportsSettings.pdf, {cancel: this.cancelReportDownload() }).then(result => this.downloadReportBlob(result.data));
      // viewer.export('pdf', this.exportsSettings.pdf, {cancel: this.cancelReportDownload() }).then(result => result.download('EmployeeReport'));
    // },

    async getAlienAPI(url) {
      return await new Promise((resolve, reject) => {
        Vue.axios.get(url).then(response => resolve(response)).catch(error => reject(error));
      });
    },

    async loadData() {
      let data = null;
      await fetch(this.dataSource)
        .then(async response => await response.json())
        .then(json => {
          data = json;
        });
      return data;
    },
  },
};
</script>

<style scoped>
.download-button-section {
  cursor: pointer;
  margin: 15px;
  text-align: center;
}
.download-button-section .downlad-report-button {
  cursor: pointer;
  z-index: 9999;
  background-color: #008f4f;
  color: #fff;
  border-radius: 6px;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
}
/* Add any additional styles here */
</style>