import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'


import ActiveReports from '@/components/ActiveReports';
import DefaultOne from '@/components/DefaultOne';
import TestMe from '@/components/TestMe';

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'defaultOne',
    component: DefaultOne

  },

  {
    path: '/jobReports/:url',
    name: 'jobReports',
    component: ActiveReports
  },
  {
    path: '/test/:name',
    name: 'testMe',
    component: TestMe
  },
  {
    path: '/employeeReport/:url',
    name: 'jobReports',
    component: ActiveReports
  },
  {
    path: '/job-report/:url',
    name: 'job-report',
    component: ActiveReports
  }
]

// mode: 'history',
// base: process.env.BASE_URL,
const router = new VueRouter({
  routes
})

export default router
