<template>
  <div>
  Hello ARJS {{ $route.params.name }} + Are you happy ?
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>